import React from 'react'
import { graphql, PageProps, Link } from 'gatsby'
import Iframe from 'react-iframe'
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight'
import Layout from 'src/components/Layout'
import Seo from 'src/components/Seo'
import Article from 'src/components/Article'
import { StoryQuery } from 'types/graphql-types'
//@ts-ignore
import image01 from 'src/images/background/01.jpg'
//@ts-ignore
import image02 from 'src/images/background/02.jpg'
//@ts-ignore
import image03 from 'src/images/background/03.jpg'
//@ts-ignore
import image04 from 'src/images/background/04.jpg'
//@ts-ignore
import image05 from 'src/images/background/05.jpg'
//@ts-ignore
import image06 from 'src/images/background/06.jpg'
//@ts-ignore
import image07 from 'src/images/background/07.jpg'
//@ts-ignore
import image08 from 'src/images/background/08.jpg'

const bgSet = [
  image01,
  image02,
  image03,
  image04,
  image05,
  image06,
  image07,
  image08,
]

const Story: React.FC<PageProps<StoryQuery>> = ({ data }) => {
  const index = Number(data.story?.index)
  return (
    <Layout
      heroImagePath={bgSet[index - 1]}
      title={data.story?.title}
      subTitle={data.story?.subTitle}
    >
      <Seo title={`${data.story?.title}`} />
      <Article>
        <div
          className="prose max-w-prose"
          dangerouslySetInnerHTML={{ __html: data.story?.body || '' }}
        />
        {data.story?.trackId && (
          <div className="pt-16 md:pt-24 w-full">
            <Iframe
              width="100%"
              height="120px"
              url={`https://bandcamp.com/EmbeddedPlayer/size=large/bgcol=ffffff/linkcol=333333/tracklist=false/artwork=small/track=${data.story.trackId}/transparent=true/`}
            >
              <a href="https://kyleideta.bandcamp.com/album/gardener">
                Gardener by Kyle Ideta
              </a>
            </Iframe>
          </div>
        )}
        <footer className="flex justify-between items-center w-full text-2xl pt-24">
          <div>
            {index > 1 && (
              <Link to={`../${index - 1}`}>
                <FiChevronLeft />
              </Link>
            )}
          </div>
          <div>
            {index < 8 && (
              <Link to={`../${index + 1}`}>
                <FiChevronRight />
              </Link>
            )}
          </div>
        </footer>
      </Article>
    </Layout>
  )
}

export default Story

export const query = graphql`
  query Story($language: String!, $index: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    story: storyJson(index: { eq: $index }, language: { eq: $language }) {
      id
      title
      subTitle
      index
      body
      albumId
      trackId
    }
  }
`
